.titleText {
    color: #167C9F;
    /* font-family: Inter; */
    /* font-size: 48px; */
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.48px;
    margin: 0;
}

.logContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    height: 500px;
}

.inputBoxLog{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 5px;
}

.inputStyleLog{
    background-color: #f5f5f5;
    /* color: #0c4a73; */
    border: none;
    outline: none;
    height: 36px;
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 5px;
}