.benchmark_container {
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
}

.benchmark_header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
    /* top: 0;
    z-index: 99;
    position: sticky; */
}

.benchmark_content {
    background-color: #ffffff;
}

.benchmark_ad_year {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}

.benchmark_heading {
    font-size: 20px;
    padding: 5px 10px 5px 10px;
    margin-top: 10px;
    font-weight: 600;
    border-left: 5px solid var(--active-color);
    background-color: #ffffff;
}

.benchmark_category {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.benchmark_years {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.benchmark_year {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.benchmark_year_el {
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.benchmark_adtype {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.benchmark_kpi {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.benchmark_button {
    border: 2px solid #ffffff;
    border-radius: 10px;
    padding: 8px 16px;
    min-width: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #ffffff;
}

.benchmark_button_active {
    border: 2px solid black;
    border-radius: 10px;
    padding: 4px 8px;
    min-width: 60px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #ffffff;
}

.benchmark_graph {
    display: flex;
    gap: 10px;
    align-items: center;
}

.searchResultBox {
    min-width: 150px;
    max-width: 250px;
    z-index: 199;
    top: 120px;
    /* right: 100px; */
    background-color: #ffffff;
    position: absolute;
    cursor: pointer;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 5px;
}

.searchInputContainer {
    margin: 10px;
}

.catSearchInputContainer {
    outline-color: #03A0E7;
    width: 100%;
    border: 2px solid #6c757d;
    border-radius: 5px;
    text-align: center;
}

.searchInput {
    border: none;
    outline: none;
}

.catListContainer {
    overflow-y: auto;
    max-height: 250px;
    margin-bottom: 10px;
}

.catName {
    display: flex;
    justify-content: space-between;
    border: 2px solid #167C9F;
    border-radius: 5px;
    padding: 3px 8px 3px 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}