/* 
.loginContainer {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
}

.loginContainerHeader {
    color: #6c757d;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    color: #6c757d;
    font-weight: 470;
}

.loginContainer>input {
    background-color: #f5f5f5;
    margin-bottom: 5px;
    margin-top: 5px;
}

.rightLoginPageLoader {
    position: relative;
    top: 240px;
    height: 0px;
}

.inputStyle {
    background-color: #f5f5f5;
    color: #0c4a73;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
} */

.loginContainer {
    display: flex;
    height: 500px;
    border-radius: 20px;
}

.left {
    width: 50%;
    background-color: #0F4E6D;
    padding: 20px 60px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.left>h3 {
    color: #fff;
}

.right {
    width: 50%;
    background-color: #fff;
    padding: 40px 60px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.inputBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 5px;

}

.inputStyle {
    background-color: #f5f5f5;
    /* color: #0c4a73; */
    border: none;
    outline: none;
    height: 36px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 5px;
}

.rightText {
    color: #167C9F;
    /* font-family: Inter; */
    /* font-size: 48px; */
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.48px;
}

.loginButton {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 40px;
    background: #27607C;
}

.otpContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    height: 500px;
}

.inputOTP {
    display: flex;
    justify-content: center;
    gap: 10px;

}

.inputOtpStyle {
    width: 30px;
    height: 40px;
    background-color: #D9D9D9;
    /* color: #0c4a73; */
    border: none;
    outline: none;
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    border-radius: 10px;
}

.errorContainer {
    color: red;
}

.loginLinkContainer {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.loginLink {
    border: none;
    background-color: #fff;
    color: rgb(59, 127, 246);
}